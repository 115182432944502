// npm
import React, { memo, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Divider, styled, Typography } from '@mui/material'
import { InView } from 'react-intersection-observer'
import { SwiperSlide } from 'swiper/react'

// components
import TagButton from '@atoms/buttons/TagButton'
import FixedHeightHeroContainer from '@atoms/hero/FixedHeightHeroContainer'
import Flex from '@atoms/layout/Flex'
import DateAndCategory from '@molecules/blog/DateAndCategory'
import Card from '@molecules/cards/Card'
import ContentComponents from '@molecules/contentComponents/ContentComponents'
import ShareButtons from '@molecules/social/ShareButtons'
import ContentHeroImage from '@organisms/content/ContentHeroImage'
import ContentSectionSwiperCards from '@molecules/sections/components/ContentSectionSwiperCards'
import Layout from '@organisms/global/Layout'
import Markdown from '@atoms/text/Markdown'

interface Props {
  data: Queries.BlogPostDetailQuery
}

const SecondaryInView = styled(InView)({
  height: 0,
})

const BlogPostDetail = ({ data }: Props) => {
  const d = data.datoCmsBlogPost
  const [secondaryInView, setSecondaryInView] = useState(false)

  const updateSecondaryInView = (inView: boolean) => {
    setSecondaryInView(inView)
  }

  // extract tags for seo
  let seoTags: string[] = []
  // d.tags.map((tag: Queries.DatoCmsUnionForDatoCmsBlogPostTags) => {
  {
    d.keywordTags &&
      d.keywordTags.map((tag: Queries.keywordButtonFragment) => {
        seoTags.push(tag.shortTitle)
      })
  }

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
        customDescription: d.description,
        extraTitle: seoTags.join(', '),
      }}
      headerProps={{
        sizeVariant: 'compact',
        themeVariant: 'light',
        forceSolidNav: true,
        useSecondaryInView: true,
        secondaryInView: secondaryInView,
      }}
    >
      <FixedHeightHeroContainer sizeVariant="compact" bgcolor="secondary.main">
        <Container
          maxWidth="lg"
          className="fixed-height"
          sx={{ height: '100%', position: 'relative', textAlign: 'center' }}
        >
          <Box
            className="fixed-height"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
              py: { xs: 8, sm: 10, md: 12, lg: 14, xl: 16 },
              color: 'white',
            }}
          >
            <DateAndCategory
              date={d.meta.createdAt}
              category={d.category[0]}
              categoryLinkColorVariant="primary-light"
              mb={2}
            />
            <Typography component="h1" variant="h2">
              {d.title}
            </Typography>
            {/* <Box mt={2}>
              {d.tags.map(
                (
                  tag: Queries.DatoCmsUnionForDatoCmsBlogPostTags,
                  index: number
                ) => {
                  return (
                    <TagButton
                      key={`${tag.originalId}-${index}`}
                      slug={tag.slug}
                      apiKey={tag.model.apiKey}
                    >
                      {tag.summaryTitle}
                    </TagButton>
                  )
                }
              )}
            </Box> */}
            {d.heroTextNode && (
              <Box typography="heroText" mt={1}>
                <Markdown html={d.heroTextNode.childMarkdownRemark.html} />
              </Box>
            )}
          </Box>
        </Container>
      </FixedHeightHeroContainer>
      <SecondaryInView
        onChange={(inView) => {
          updateSecondaryInView(!inView)
        }}
      >
        &nbsp;
      </SecondaryInView>
      <ContentHeroImage
        imageLandscape={d.heroImageLandscape.gatsbyImageData}
        imagePortrait={d.heroImagePortrait.gatsbyImageData}
      />
      <ContentComponents components={d.leadText} />
      <ContentComponents components={d.contentComponents} />
      <Container maxWidth="md" sx={{ pb: 8 }}>
        <Divider
          sx={{
            mt: 6,
            mb: 4,
            backgroundVideo: 'rgba(32,38,49,.1)',
          }}
        />

        <Box mb={1}>Tags:</Box>

        {d.keywordTags.map(
          (tag: Queries.keywordButtonFragment, index: number) => {
            if (tag.shortTitle) {
              return (
                <TagButton
                  key={`${tag.originalId}-${index}`}
                  slug={tag.slug}
                  apiKey={tag.model.apiKey}
                >
                  {tag.shortTitle}
                </TagButton>
              )
            }
          }
        )}

        <Flex alignCenter justifyContent="space-between" mt={4}>
          <Box>Share:</Box>
          <Box>
            <ShareButtons path={`blog/${d.slug}`} description={d.description} />
          </Box>
        </Flex>
      </Container>
      {d.relatedBlogPosts && d.relatedBlogPosts.length > 0 && (
        <ContentSectionSwiperCards
          wrapperProps={{ bgcolor: 'primary.light', color: 'text.primary' }}
          keyId="related-posts"
          mainHeading="Related Posts"
          smallHeading="The Journal"
          smallHeadingProps={{ color: 'primary.main' }}
        >
          {d.relatedBlogPosts.map((post: Queries.DatoCmsBlogPost) => {
            return (
              <SwiperSlide key={post.originalId}>
                <Card data={post} />
              </SwiperSlide>
            )
          })}
        </ContentSectionSwiperCards>
      )}
    </Layout>
  )
}

export default memo(BlogPostDetail)

export const pageQuery = graphql`
  query BlogPostDetail($originalId: String!) {
    datoCmsBlogPost(originalId: { eq: $originalId }) {
      originalId
      title
      description
      keywordTags {
        ...keywordButton
      }
      category {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      leadText {
        ... on DatoCmsComponentLeadText {
          ...componentLeadText
        }
      }
      contentComponents {
        ... on DatoCmsComponentHeading {
          ...componentHeading
        }
        ... on DatoCmsComponentText {
          ...componentText
        }
        ... on DatoCmsComponentImage {
          ...componentImage
        }
        ... on DatoCmsComponentImageText {
          ...componentImageText
        }
        ... on DatoCmsComponentQuote {
          ...componentQuote
        }
        ... on DatoCmsComponentYoutubeVideo {
          ...componentYoutubeVideo
        }
        ... on DatoCmsComponentProjectsSummary {
          ...componentProjectsSummary
        }
      }
      meta {
        createdAt(formatString: "D MMM")
      }
      heroImageLandscape: heroImage {
        gatsbyImageData(
          width: 1536
          layout: CONSTRAINED
          imgixParams: { ar: "1440:740", fit: "crop", crop: "focalpoint" }
        )
      }
      heroImagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: CONSTRAINED
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint" }
        )
      }
      heroTextNode {
        childMarkdownRemark {
          html
        }
      }
      relatedBlogPosts {
        ...cardBlogPost
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
