// npm
import React, { memo } from 'react'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Container, styled, useTheme } from '@mui/material'
import { relative } from 'node:path/win32'

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  position: 'relative',
  ['&:before']: {
    top: 0,
    display: 'block',
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '180px',
    backgroundColor: theme.palette.secondary.main,
  },
}))

const StyledImage = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '75%',
  },
}))

interface Props {
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
}

const ContentSectionBgImage = ({ imageLandscape, imagePortrait }: Props) => {
  const theme = useTheme()
  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.lg}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )
  return (
    <Wrapper>
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <StyledImage image={images} alt="" />
      </Container>
    </Wrapper>
  )
}

export default memo(ContentSectionBgImage)
