import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { styled } from '@mui/material'
import {
  LocalPostOffice,
  LinkedIn,
  Facebook,
  Twitter,
} from '@mui/icons-material'

import { ButtonProps } from '@atoms/buttons/Button'
import ExternalButtonLink from '@atoms/buttonLinks/ExternalButtonLink'

export interface ShareButtonsProps {
  path: string
  description: string
  buttonProps?: Omit<ButtonProps, 'children'>
}

const StyledExternalButtonLink = styled(ExternalButtonLink)(({ theme }) => ({
  margin: '0 3px',
}))

const ShareButtons = ({
  path,
  description,
  buttonProps,
}: ShareButtonsProps) => {
  const styledButtonProps = {
    ...buttonProps,
    sizeVariant: buttonProps?.sizeVariant ? buttonProps?.sizeVariant : 'lg',
    variant: 'contained' as const,
    colorVariant: 'contained-light',
    round: true,
  }

  return (
    <StaticQuery<Queries.ShareButtonsQuery>
      query={graphql`
        query ShareButtons {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const shareUrl = `${data?.site?.siteMetadata?.siteUrl}/${path}`

        return (
          <>
            <StyledExternalButtonLink
              href={`mailto:?subject=${description}&body=${shareUrl}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Email',
              }}
            >
              <LocalPostOffice fontSize="small" />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${description}&summary=${description}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via LinkedIn',
              }}
            >
              <LinkedIn fontSize="small" />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Facebook',
              }}
            >
              <Facebook fontSize="small" />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${description}&via=_AndreiAnthony`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Twitter',
              }}
            >
              <Twitter fontSize="small" />
            </StyledExternalButtonLink>
          </>
        )
      }}
    />
  )
}

export default ShareButtons
